<template>

<app-content :loading="is.loading">

	<app-content-head title="Badge" subtitle="Manage your badge details and see any purchased add-ons.">

		<app-content-head-actions-item text="Transfer" icon="transfer" v-on:click="onTransferClick" v-if="transfer" />

	</app-content-head>

	<app-content-body v-if="!is.loading">

		<app-content-summary>

			<app-content-summary-item label="No." :value="badge.reference" v-if="$convention.settings.badges_numbers_public" />
			<app-content-summary-item label="Ref." :value="badge.reference" v-if="!$convention.settings.badges_numbers_public" />
			<app-content-summary-item label="Type" :grow="true" :value="typeName" />
			<app-content-summary-item v-if="isGifted" label="Purchased By" :value="badge.sale.user.name" />
			<app-content-summary-item label="Registered On" :value="badge.date | localDate('MM/DD/YYYY h:mma')" />
			<app-content-summary-item label="Checked in" :value="badgeReceived" v-if="badge.received" />

		</app-content-summary>

		<com-details :badge="badge" :preview="preview" v-on:changed="load" />

		<com-addons :addons="addons" v-if="addons.length" v-on:refresh="onRefresh" />
		
	</app-content-body>

</app-content>

</template>

<script>

import comDetails from './conventionregistrationbadge/Details'
import comAddons from './conventionregistrationbadge/Addons'

export default {

	components: {
		comDetails,
		comAddons
	},

	data: function() {

		return {
			is: {
				loading: true
			},
			badge: false,
			preview: false,
			transfer: false,
			addons: []
		}

	},

	created: function() {

		this.load()

	},

	computed: {

		typeName: function() {

			if (this.badge.stacked) {

				return this.$_.pluck(this.badge.stacked, 'name').join(', ')

			} else {

				return this.badge.type.name

			}

		},

		badgeReceived: function() {

			return this.$options.filters.localDate(this.badge.received, 'h:mma') + ' on ' + this.$options.filters.localDate(this.badge.received, 'dddd')

		},

		isGifted: function() {

			return (this.badge.sale) ? this.badge.sale.user.id !== this.badge.user.id : false

		}

	},

	methods: {

		onRefresh: function() {

			this.is.loading = true

			this.load()

		},

		onTransferClick: function() {

			this.$router.push({
				name: 'Convention.Registration.Transfer'
			})

		},

		load: function() {

			this.$api.get('convention/registration/badge').then(function(json) {

				this.badge = json.badge
				this.preview = json.image
				this.addons = json.addons
				this.transfer = json.transfer

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>